<template>
  <div class="relative w-full font-Gilroy text-black">
    <Header />
    <div id="section-banner" class="relative">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="require('@/assets/images/marketing/banner-features.png')"
          class="w-full"
        />
      </router-link>
    </div>
    <div id="section-consumer-grade-exps" class="mt-20 px-20">
      <div class="flex justify-center">
        <img
          :src="require('@/assets/images/marketing/consumer-grade.png')"
          class="w-9/12"
        />
      </div>
      <img :src="require('@/assets/images/marketing/features-content.png')" />
    </div>
    <div id="section-why-texperia" class="mt-20 px-20">
      <div class="flex justify-center">
        <img
          :src="require('@/assets/images/marketing/why-texperia.png')"
          class="w-9/12"
        />
      </div>
      <div class="grid grid-cols-3 gap-4 mt-10">
        <div v-for="index in 5" :key="index">
          <img
            :src="
              require('@/assets/images/marketing/features-' + index + '.png')
            "
          />
        </div>
      </div>
    </div>
    <div id="demo-banner" class="overflow-hidden">
      <img
        :src="require('@/assets/images/marketing/banner-demo.png')"
        class="w-full transform scale-150 my-20"
      />
    </div>
    <div id="banner-book-demo" class="p-20">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="require('@/assets/images/marketing/features-solving-cx.png')"
          class="w-full"
        />
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Marketing/Desktop/Common/Header.vue";
import Footer from "@/components/Marketing/Desktop/Common/Footer.vue";

export default {
  name: "Features",
  components: { Header, Footer },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {},
};
</script>
<style scoped></style>
