<template>
  <div class="relative w-full font-Gilroy">
    <Header />
    <div id="section-banner" class="relative">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="require('@/assets/images/marketing/mobile/banner-features.png')"
          class="w-full"
        />
      </router-link>
    </div>
    <div id="section-consumer-grade-exps" class="mt-10 px-6 text-black">
      <div class="flex justify-center">
        <img
          :src="require('@/assets/images/marketing/consumer-grade.png')"
          class="w-full"
        />
      </div>
      <img
        :src="require('@/assets/images/marketing/mobile/features-content.svg')"
      />
    </div>
    <div id="section-why-texperia" class="mt-10 px-6">
      <div class="flex justify-center">
        <img
          :src="require('@/assets/images/marketing/why-texperia.png')"
          class="w-full"
        />
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <div v-for="index in 5" :key="index">
          <img
            :src="
              require('@/assets/images/marketing/features-' + index + '.png')
            "
          />
        </div>
      </div>
    </div>
    <div id="demo-banner" class="overflow-hidden">
      <img
        :src="require('@/assets/images/marketing/mobile/banner-demo.png')"
        class="w-full transform scale-125 my-10"
      />
    </div>
    <div id="banner-book-demo" class="p-6">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="
            require('@/assets/images/marketing/mobile/features-solving-cx.png')
          "
          class="w-full"
        />
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Marketing/Mobile/Common/Header.vue";
import Footer from "@/components/Marketing/Mobile/Common/Footer.vue";

export default {
  name: "Features",
  components: { Header, Footer },
  data() {
    return {};
  },
  created() {},
  updated() {},
  methods: {
    getImgUrl(index) {
      return require("@/assets/images/marketing/mobile/features-" +
        index +
        ".png");
    },
  },
};
</script>
<style scoped></style>
